<template>
  <v-container fluid>  

    <!-- Section Path File/Modul -->
    <v-breadcrumbs 
        :items="pathModul"
        divider="-"
        normal
        class="pathModul"
    ></v-breadcrumbs>

    <!-- Section Content Report - Daily -->
    <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-6 pt-0' : 'px-1'" >

        <!-- Row Filtering Input/Select/Button -->
        <v-row align="center">

            <!-- Column Select Stations -->
            <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'">
                <v-select
                @change="selectStations(modelSelectStation)"
                :items="listStations"
                label="Station"
                prepend-inner-icon="mdi-store-marker"
                v-model="modelSelectStation"
                hide-details
                solo
                dense
                ></v-select>
            </v-col>

            <!-- Column Select Calendar -->
            <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'">
                <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                v-model="modelPopupCalendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="modelSelectDate"
                    label="Date"
                    prepend-inner-icon="mdi-calendar-today"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    solo
                    dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                  v-model="modelSelectDate"
                  @input="modelPopupCalendar = false"
                  ></v-date-picker>
                </v-menu>
            </v-col>

            <!-- Column Generate Button -->
            <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'">

              <v-btn
              class="white--text button mt-3"
              solo
              dense
              normal
              @click="GenerateTable()"
              :loading="loadingBtnGenerateReport"
              :disabled="selectedStationID == ''"
              >
                GENERATE REPORT
              </v-btn>
              

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  color="excel"
                  class="mt-3 "
                  style="float: right;"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                  @click="exportReport"
                  >
                    <v-icon
                    color="white"
                    center
                    dark
                    >
                      mdi-microsoft-excel
                    </v-icon>
                  </v-btn>
                </template>
                <span>Export to Excel</span>
              </v-tooltip>

              <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  color="csv"
                  class="mt-3 mx-2"
                  style="float: right;"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                  @click="exportCSV()"
                  >
                    <v-icon
                    color="white"
                    center
                    dark
                    >
                        mdi-file-delimited
                    </v-icon>
                  </v-btn>
                </template>
                <span>Export to CSV</span>
              </v-tooltip>
            </v-col>

        </v-row>

        <!-- Row Datatable & Chart -->
        <v-row justify="center">
          <v-col cols="12" class="pb-0">

            <v-card>

              <v-tabs v-model="tabDaily">
                  <v-tab>DAILY</v-tab>
              </v-tabs>

              <v-tabs-items v-model="tabDaily">
                <v-tab-item class="pa-3">
                  
                  <!-- Title -->
                  <v-row class="pa-2" justify="center">
                    <v-col cols="12" class="text-md-center">
                      <h4 v-show="theadDaily.length != 0">WATER QUALITY STATUS FOR {{displayTitleStationLocation}} ({{displayTitleStationID}}) {{displayTitleDate}}</h4>
                      <!-- <h4>Title</h4> -->
                    </v-col>
                  </v-row>

                  <!-- Chart/Back Button -->
                  <v-row>
                    <v-col cols="12" class="text-md-right">
                      
                      <v-btn
                      v-if="viewContent == 'datatable'"
                      color="button"
                      solo
                      dense
                      normal
                      :disabled="selectedStationID == null"
                      class="ma-2 white--text"
                      style="position: absolute;right: 8px; top:45px;"
                      @click="generateChart()"
                      >
                        CHART
                        <v-icon right dark big>
                          mdi-chart-line
                        </v-icon>
                      </v-btn>

                      <v-btn
                      v-else-if="viewContent == 'chart'"
                      solo
                      dense
                      normal
                      class="ma-2 white--text btn-generate button" 
                      style="position: absolute;right: 8px; top:45px;z-index:1;"
                      @click="backToTable()"
                      >
                        BACK
                        <v-icon right dark big >
                          mdi-arrow-left-top-bold
                        </v-icon>
                      </v-btn>

                    </v-col>
                  </v-row>

                  <!-- Datatable -->
                  <v-row v-if="viewContent == 'datatable'">
                    <v-col cols="12" class="text-md-center">
                      <v-data-table
                      :headers="theadDaily"
                      :items="tbodyDaily"
                      :header-props="{ sortIcon: null }"
                      class="elevation-1 mt-10 tableDailyReport"
                      :class="{'datatable-mobile' : !$vuetify.breakpoint.lgAndUp}"
                      :items-per-page="itemPerPage"
                      no-select-on-click
                      fixed-header
                      :mobile-breakpoint="0"
                      :loading="loadingDatatable"
                      loading-text="Data Loading... Please wait"
                      >
                        <template v-slot:header>
                            <thead v-if="inExportExcel ? false : true" class="header-colspan">
                              <tr class="">
                                <th id="th_hub" class="th-hub" v-for="(item,i) in theadDaily" :key="i">
                                  <input 
                                  v-if="item.value != 'datetime_frontend' && item.value != 'station_a' && item.value != 'LOCATION'"
                                  :value="item.value"
                                  type="checkbox" 
                                  v-model="item.modelCheckbox" 
                                  @change="checkedValue($event)"
                                  >
                                </th>
                              </tr>
                              <!-- <tr v-if="!$vuetify.breakpoint.lgAndUp">
                                <th id="th_hub" class="th-hub" v-for="(item,i) in theadDaily" :key="i">
                                  {{item.text}}
                                </th>
                              </tr> -->
                            </thead>
                        </template>

                        <template
                        v-slot:body="{ items }"
                        >
                          <tbody>
                            <tr
                              v-for="(item,x) in items"
                              :key="x"
                            >
                              <!-- <td>{{ item.name }}</td>
                              <td>CONTENT</td>
                              <td>CONTENT</td>
                              <td>CONTENT</td>
                              <td>CONTENT</td>
                              <td>{{item}}</td> -->
                              <td v-for="(itemtd,i) in theadDaily" :key="i" >
                                <!-- {{item[itemtd.value]}} -->
                                <span v-if="item[itemtd.value+'_FLAG'] != null" class="red--text font-weight-bold">
                                  {{ item[itemtd.value+'_FLAG'] }}
                                </span>
                                <span v-else>
                                  <span v-if="item[itemtd.value] == null" class="font-weight-bold">
                                    NA
                                  </span>
                                  <span v-else>
                                    {{ item[itemtd.value] }}
                                  </span>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                        

                      </v-data-table>
                    </v-col>
                  </v-row>

                  <!-- Chart -->
                  <v-row v-else-if="viewContent == 'chart'">
                    <v-col cols="12">
                      <highcharts 
                      :constructorType="'stockChart'" 
                      class="hc" 
                      :options="chartOptions" 
                      ref="chart"
                      ></highcharts>
                    </v-col>
                  </v-row>

                </v-tab-item>
              </v-tabs-items>

            </v-card>
            
          </v-col>
        </v-row>

        <v-footer padless>
          <v-col
          class="text-center footer-report"
          cols="12"
          >
            <span class="one-text-footer mx-5">
                  <span class="red--text">C</span>
                  <span class="blue--text">=CALIBRATION</span>
              </span>
              <span class="one-text-footer mx-5">
                  <span class="red--text">M</span>
                  <span class="blue--text">=MAINTENANCE</span>
              </span>
              <span class="one-text-footer mx-5 dontBreak">
                  <span class="red--text">DOR</span>
                  <span class="blue--text">=DATA OUT OF SENSOR RANGE</span>
              </span><br>
              <span class="one-text-footer mx-5">
                  <span class="red--text">OUTL</span>
                  <span class="blue--text">=OUTLIER</span>
              </span>
              <span class="one-text-footer mx-5">
                  <span>NA</span>
                  <span class="blue--text">=DATA NOT AVAILABLE</span>
              </span>
          </v-col>
        </v-footer>

    </v-container>

  </v-container>
</template>



<script>

import axios from 'axios';
import Papa from "papaparse";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
// import renderPDF from 'chrome-headless-render-pdf';


export default {

  

  data: () => ({

      // General
      viewContent: "datatable",
      listParams: [],
      // (Stations)
      listStations: [],
      modelSelectStation: '',
      selectedStationID : '',
      selectedStationLocation : '',
      // (Calendar)
      modelPopupCalendar: false,
      modelSelectDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),


      // Datatable
      displayTitleStationLocation: "",
      displayTitleStationID: "",
      displayTitleDate: "",
      tabDaily: null,
      loadingBtnGenerateReport: false,
      loadingDatatable: false,
      inExportExcel: false,
      theadDaily: [],
      theadSubTime : {
        text: 'Datetime',
        sortable: false,
        value: 'datetime_frontend',
        divider: true,
        align: 'center',
      },
      theadSubStationID : {
        text: 'Station ID',
        sortable: false,
        value: 'station_a',
        divider: true,
        align: 'center',
      },
      theadSubLocation : {
        text: 'Location',
        sortable: false,
        value: 'LOCATION',
        divider: true,
        align: 'center',
      },
      tbodyDaily: [],


      // Chart




      GlobalDataDaily : [],
      DataRenderingChart : [],

      

      

      chartCon: [],

      pdfExc: [],
      
      pathModul: [
          {
              text: 'Home',
              disabled: false,
              href: '/Mapviewer'
          },
          {
              text: 'Report - Daily',
              disabled: true,
              href: '/Report/DailyReport',
          },
      ],
      itemStation: [],

      
      
      excelDate: '',
      

      // Variable/Object for value filtering
      
      selectedDate : null,

      // Title - Subtitle After Generate Data
      stationID: null,

      // DataTable
      

      // Header Table
      mainTheader: [],
      checkboxTheader : [],
      thead_dailyReport: [],
      mainTime: {category: "TIME"},
      
      sortArraySubHeader: [],

      // Body Table
      tbody_dailyReport: [],

      // Footer Table
      itemPerPage: 10,

      exportTableParam: [],


      // Highchart formating generated
      chartOptions: {
        chart: {
          height: 600
          // type: 'spline'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
       
        yAxis: {
          // opposite:false,
          plotLines: [],
          
        },
        series: [],
        legend: {
            enabled: true
        },
      },
      
      arrayDataChart: [],

      objPlotClass: [],

      // plotLinesValue: [
     
      //   { param: "WQI", class1: 92.7, class2: 76.5, class3: 51.9, class4: 31.0, class5: 30.9, threshold: null},
      //   { param: "DO_CON", class1: 7, class2: 5, class3: 3, class4: 1, class5: 1, threshold: null},
      //   { param: "BOD", class1: 0.98, class2: 1, class3: 4, class4: 6, class5: 12, threshold: null},
      //   { param: "COD", class1: 9.98, class2: 10, class3: 25, class4: 50, class5: 100, threshold: null},
      //   { param: "NH3N", class1: 0.098, class2: 0.1, class3: 0.3, class4: 0.9, class5: 2.7, threshold: null},
      //   { param: "TSS", class1: 24.98, class2: 25, class3: 50, class4: 150, class5: 300, threshold: null},
      //   { param: "pH", class1: 7, class2: 6, class3: 5, class4: 4.98, threshold: null},
      // ],

      objClassLevel: []

      
      
  }),

  mounted() {

    this.getListStation();
    this.getListParameter();

    console.log(this.$store.getters);

  },

  methods: {

    // General
    getListStation(){

      this.listStations = [];

      axios.get(this.globalUrl+'mqm2/miqims/stations', {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {
          
          let data = response.data;

          console.log(data);

          for(let i in data){
            this.listStations.push(data[i].STATION_ID + " - " + data[i].LOCATION);
          }
          
      })
      .catch(error => {
          console.log(error);
      })

    },

    getListParameter(){

      this.listParams = [];

      axios.get(this.globalUrl+'mqm2/miqims/params', {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {
          
          let data = response.data;
          console.log(data);

          this.listParams = data;
          
      })
      .catch(error => {
          console.log(error);
      })

    },

    selectStations(data){

      this.selectedStationID = data?.split('-')[0];
      this.selectedStationLocation = data?.split('-')[1];

    },



    // Generate Table
    GenerateTable(){

      this.loadingDatatable = true;
      this.loadingBtnGenerateReport = true;

      this.displayTitleStationLocation = this.selectedStationLocation?.toUpperCase(),
      this.displayTitleStationID = this.selectedStationID,
      this.displayTitleDate = this.useConvertDisplayDate(this.modelSelectDate),

      // Clear Body Table first after generate new datatable
      this.GlobalDataDaily = [];
      this.DataRenderingChart = [];
      this.mainTheader = [];
      this.checkboxTheader = [];
      this.thead_dailyReport = [];
      this.tbody_dailyReport = [];
      this.sortArraySubHeader = [];
      this.tbodyDaily = [];

      // Generate Thead as Dynamic
      this.generateThead(this.selectedStationID);

    },

    generateThead(stationID){

      this.theadDaily = [];

      axios.get(this.globalUrl+'mqm2/miqims/params?stationid='+ stationID, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {

            let data = response.data;

            // for(let i in data){
            //   if (data[i].value == "_4_4_DDT") {
            //     data[i].category = "4";
            //   }
            // }

            for(let i in data){
              this.theadDaily.push({
                text: data[i].text,
                value: data[i].value,
                category: data[i].category,
                divider: true,
                align: "center",
                modelCheckbox: false,
              })
            }

            this.theadDaily.unshift(this.theadSubTime);

            this.generateTbody();
            
           
        })
        .catch(error => {
            console.log(error);
        })
      
    },

    generateTbody(){

      this.tbodyDaily = []

      axios.get(this.globalUrl+'mqm2/miqims/daily?startdt='+this.modelSelectDate+'&stationid='+this.selectedStationID, {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {

          let data = response.data;
          console.log(data);

          for(let i in data){
            data[i]["datetime_frontend"] = this.useConvertDisplayDatetime(data[i]["datetime_a"])
          }

          this.tbodyDaily = data;

          this.loadingBtnGenerateReport = false;
          this.loadingDatatable = false;

      })
      .catch(error => {
          this.loadingBtnGenerateReport = false;
          this.loadingDatatable = false;
          console.log(error);
      })

    },
      

    exportCSV(){

      let headerExportCsv = [];
      for(let i in this.theadDaily){
          headerExportCsv.push(this.theadDaily[i]['value']);
      }

      let keys = headerExportCsv,
      resultAfterFilter = this.tbodyDaily.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));
      let obj = resultAfterFilter;
      
      for (let i in obj){
        obj[i]["Datetime"] =  obj[i]['datetime_a']; 
        obj[i]["pH (Index)"] =  obj[i]['SI_pH']; 
        let tempPH =  obj[i]['pH'];
        obj[i]["pH "] = tempPH;
        obj[i]["Dissolved Oxygen (Saturation) (Index)"] =  obj[i]['SI_DO_SAT']; 
        obj[i]["Dissolved Oxygen (Saturation) (%Sat)"] =  obj[i]['DO_SAT']; 
        obj[i]["Total Suspended Solids (Index)"] =  obj[i]['SI_TSS']; 
        obj[i]["Total Suspended Solids (mg/L)"] =  obj[i]['TSS']; 
        obj[i]["Dissolved Oxygen (Concentration) (mg/L)"] =  obj[i]['DO_CON']; 
        obj[i]["Temperature"] =  obj[i]['TEMPERATURE']; 
        // obj[i]["Temperature (°C)"] =  obj[i]['TEMPERATURE']; 
        obj[i]["Electrical Conductivity)"] =  obj[i]['EC']; 
        obj[i]["Conductivity"] =  obj[i]['CONDUCTIVITY']; 
        // obj[i]["Electrical Conductivity (µS/cm)"] =  obj[i]['EC']; 
        // obj[i]["Conductivity (µS/cm)"] =  obj[i]['CONDUCTIVITY']; 
        obj[i]["Salinity (ppt)"] =  obj[i]['SALINITY']; 
        obj[i]["Turbidity (NTU)"] =  obj[i]['TURBIDITY']; 
        obj[i]["Depth (m)"] =  obj[i]['DEPTH']; 
        obj[i]["Solar Battery (V)"] =  obj[i]['SOLAR_BATTERY']; 

        delete obj[i]['datetime_a'];
        delete obj[i]['SI_pH'];
        delete obj[i]['pH'];
        delete obj[i]['SI_DO_SAT'];
        delete obj[i]['DO_SAT'];
        delete obj[i]['SI_TSS'];
        delete obj[i]['TSS'];
        delete obj[i]['DO_CON'];
        delete obj[i]['TEMPERATURE'];
        delete obj[i]['EC'];
        delete obj[i]['SALINITY'];
        delete obj[i]['TURBIDITY'];
        delete obj[i]['DEPTH'];
        delete obj[i]['SOLAR_BATTERY'];
        delete obj[i]['CONDUCTIVITY'];
      }

      let blob = new Blob([Papa.unparse(obj)], { type: 'text/csv;charset=utf-8;' });

      let link = document.createElement("a");

      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", 'Daily Report.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    },

    exportReport(){

      // Define your style class template.
      
      this.itemPerPage = -1;


    let headerName = "<tr = 'border:none !important' ><td colspan ='"+this.theadDaily.length+"' style = 'text-align: center; border:none !important; font-weight: bold'>WATER QUALITY STATUS FOR " + this.selectedStationLocation + " ( " + this.stationID + ") " + this.excelDate + "</td></tr>"
    

      var style = "<style> th, td { border: 10px solid;}</style>";

        var uri = "data:application/vnd.ms-excel;base64,",
          template =
            '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' +
            style +
            "</head><body><table>" +
            headerName +
            "{table}</table></body></html>",
          base64 = function (s) {
            return window.btoa(unescape(encodeURIComponent(s)));
          },
          format = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) {
              return c[p];
            });
          };

        this.inExportExcel = true;

        setTimeout(() => {
          let table = document.querySelectorAll(".v-data-table__wrapper ");

          // console.log(table[0].innerHTML)

          let tablei = table[0].innerHTML;
          // if (tablei.includes("℃")){
          tablei = tablei.replaceAll("°C", "&#176;C").replaceAll("µ", "&#181;");


          let ctx = { worksheet: "Daily Report" || "Worksheet", table: tablei };

          let a = document.createElement("a");
          a.href = uri + base64(format(template, ctx));
          a.download = "Daily Report.xls";
          //triggering the function
          a.click();

          this.inExportExcel = false;
          this.itemPerPage = 10;

        }, 1000)
        
    },

    // Get value checked from header checkbox
    checkedValue() {

        // console.log(this.theadDaily);
    },
    
    // Generate Data Chart
    generateChart(){

      this.viewContent = "chart"

      this.chartOptions.series = [];
      let objPlotClass = [];

      let thresholdValue = null;

      // Create list obj chart for params that checked true only
      for(let i in this.theadDaily){
        if(this.theadDaily[i].modelCheckbox == true){
          for(let x in this.listParams){
            if(this.theadDaily[i].value == this.listParams[x].reading){
              objPlotClass.push({
                name: this.listParams[x].fullname,
                data: [],
                showInLegend : true
              })
            }
          }
        }
      }

      // Push Data Params and epochdate
      for(let i in this.tbodyDaily){
        for(let y in this.listParams){
          for(let z in objPlotClass){
            if(objPlotClass[z].name == this.listParams[y].fullname){
              objPlotClass[z].data.push(
                [this.tbodyDaily[i].epochdate,this.tbodyDaily[i][this.listParams[y].reading]]
              )
            }
          }
        }
      }

      // console.log(objPlotClass);

      this.chartOptions.series = objPlotClass;

      console.log(this.chartOptions);

      // let keysTbody = Object.keys(this.tbodyDaily[0])
      // for(let i in this.listParams){
      //   for(let x in keysTbody){
      //     if(this.listParams[i].reading == keysTbody[x]){

      //       for(let z in this.tbodyDaily){
      //         objPlotClass.push({

      //         })
      //       }

      //     }
      //   }
      // }

      // name: obj_name,
      // data: []



        // console.log(this.tbodyDaily)

        // this.loadDataChart(this.tbodyDaily,arrStringChecked)

    },

    // Rendering Data Chart
    loadDataChart(data,checked){

      // console.log("masuk",data)

      // Clear first chart before generate another new chart
      this.chartOptions.series = [];
      // console.log(data);
      // console.log(checked);
      let thresholdValue = null;


      this.chartOptions.yAxis.plotLines = [];

      // Create object data to put in series chart formating 
      // if (checked.length == 1) {

        let objPlotClass = [];

        for (let i = 0; i < checked.length; i++) { // buka 1
        
            
            // let arrayValue = [];
            // for (let k = 0; k < data.length; k++) {
            //   let keyData = Object.keys(data[k])
            //   for (let f = 0; f < keyData.length; f++) {
            //     if(keyData[f] == checked[0]){
            //       arrayValue.push(data[k].epochdate,data[k][keyData[f]])
            //     }
            //   }
            // }

            console.log(checked[i])
            let obj_name;

            if(checked[i] == "SI_pH"){
              obj_name = "pH (Index)"
            } 
           else if(checked[i] == "SI_DO_SAT"){
              obj_name = "Dissolved Oxygen (Saturation) (Index)"
            } 
             else if(checked[i] == "DO_SAT"){
              
              obj_name = "Dissolved Oxygen (Saturation) (% Sat)"
            } 
             else if(checked[i] == "SI_TSS"){
              obj_name = "Total Suspended Solids (Index)"
            } 
             else if(checked[i] == "TSS"){
              obj_name = "Total Suspended Solids (mg/L)"
            } 
             else if(checked[i] == "DO_CON"){
              obj_name = "Dissolved Oxygen (Concentration) (mg/L)"
            } 
             else if(checked[i] == "TEMPERATURE"){
              obj_name = "Temperature (°C)"
            } 
             else if(checked[i] == "EC"){
              obj_name = "Electrical Conductivity (µS/cm)"
            } 
             else if(checked[i] == "SALINITY"){
              obj_name = "Salinity (ppt)"
            } 
             else if(checked[i] == "TURBIDITY"){
              obj_name = "Turbidity (NTU)"
            } 
             else if(checked[i] == "DEPTH"){
              obj_name = "Sonde Depth (m)"
            } 
             else if(checked[i] == "SOLAR_BATTERY"){
              obj_name = "Solar battery (V)"
            } else {
              obj_name = checked[i]
            }
         
            let obj = {
                name: obj_name,
                data: []
              }
              data.forEach(function(res,k){
                obj.data.push([res.epochdate,res[checked[i]]]);
              });
              this.chartOptions.series.push(obj);

            // console.log("on",obj.name)
            // for(let z = 0; z < checked.length; z++){
            //   let obj = {
            //     showInLegend: true,
            //     // type: 'scatter',
            //     name: checked[z],
            //     data: [],
            //     threshold: this.plotLinesValue[i].threshold
            //   }
            //   data.forEach(function(res,k){
            //     obj.data.push([res.epochdate,res[checked[z]]]);
            //     // console.log(Math.max(res));
            //   });

            //   // console.log("obj",obj)
            //   this.chartOptions.series.push(obj);
            // }
          
          } // tutup

        // } // tutup 2

      // console.log(this.chartOptions.yAxis.plotLines);
      //  } // tutup 3
      //   else{
      //     this.chartOptions.yAxis.plotLines = [];
      //   }

      // for(let z = 0; z < checked.length; z++){
      //   let obj = {
      //     showInLegend: true,
      //     // type: 'scatter',
      //     // name: checked[z],
      //     data: [],
      //     threshold: thresholdValue
      //   }
      //   // data.forEach(function(res,k){
      //   //   obj.data.push([res.epochdate,res[checked[z]]]);
      //   // });

      //   console.log("obj",obj);
      //   this.chartOptions.series.push(obj);
      // }

      // Subtitle Chart

      // console.log(checked);
      this.chartOptions.subtitle.text = "WATER QUALITY STATUS FOR "+ this.selectedStationLocation +" ("+ this.selectedStationID.split(' - ')[0] +") " + this.useConvertDisplayDate(this.modelSelectDate)


      // this.chartOptions.series = [];

    },

    // Back to Table Form from Chart Form
    backToTable(){
      this.viewContent = "datatable";
      this.chartOptions.series = [];
    }
    
  },

}
</script>



<style lang="scss">

  @import '~scss/main';


  // Nuzul
  .tableDailyReport > .v-data-table__wrapper > table > .v-data-table-header > tr > th{
    border-bottom: 0px !important;
    position: sticky !important;
    top: 48px !important;
    z-index: 2 !important;
  }

  // Korang

   /* Section Path File/Modul */
  .pathModul{
    padding-bottom: 0px !important;
  }

  .pathModul > li:nth-child(3) > a{
    color: black !important;
  }

  /* .v-breadcrumbs__item {
    color: black !important;
  } */

  /* Section Filtering Input/Select/Button */
  .container-filtering{
    padding: 0px;
  }



  .v-text-field.v-text-field--solo .v-input__control,
  .btn-generate .v-btn .v-btn--contained{
      min-height: 10px;
      margin-top: 10px;
  }

  .btn-download-table{
    margin-right: 8px;
  }

  /* Section Result Generated Table Report */
  .container-result-generated{
    background: white;
    //padding: 5px 0px;
    // height: calc(100vh - 250px);
  }

  .th-sub{
    background: white !important;
  }

  .subTitle{
    text-align: center !important;
    background: white;
    // color: #1976D2 !important;
    display: block;
  }

  .header-colspan > tr:nth-child(1) > th{
    text-align: center !important;
    /* border-left:  0.1px solid #e0e0e0; */
    border-right:  0.1px solid white;
    border-left:  0.1px solid white;
    /* background: #607d8b; */
    // background: #4495D1; 
    background-image: linear-gradient(to bottom, #0aafdd, #1476bf) !important;
    color: white !important; 
  }

  .header-colspan > tr:nth-child(2) > th{
    text-align: center !important;
    /* border-left:  0.1px solid #e0e0e0; */
    // border-right:  0.1px solid #e0e0e0;
    /* border-top: 0px solid white !important; */
    // border-bottom: thin solid rgb(255 255 255 / 12%) !important;
    border: 0px solid white;
    color: black !important;
    height: 30px !important;
    padding: 20px !important;
  }

  .v-data-table-header > tr:nth-child(1) > th{
    border: 0px solid white !important;
  }

  .v-data-table-header th {
    white-space: nowrap;
    padding: 5px 16px !important;
  }

  .checkbox-header-dailyReport > th:nth-child(1) > input{
    display: none;
  }

  .checkbox-header-dailyReport{
    display: none;
  }

  .tableDailyReport{
    margin: 10px 0px;
    border: 0.1px solid #e0e0e0;
  }

  // .tableDailyReport > div:nth-child(1) > table > thead:nth-child(3) > tr > th{
  //   background: white !important;
  // }

  .tableDailyReport > .v-data-table__wrapper > table > thead > tr > th{
    border-right: 0.1px solid white !important;
    border-left: 0.1px solid white !important;

  }
  // .tableDailyReport > .v-data-table__wrapper > table > thead:nth-child(1) > tr:nth-child(2) > th{
  //   border-right: 0.1px solid white !important;
  // }

  // .th-sub{
  //   background: #02b2c3 !important;
  //   color: white !important;
  //   border-right: 0.1px solid white !important;
  //   border-left: 0.1px solid white !important;
  // }


  .tableDailyReport > .v-data-table__wrapper > table > thead:nth-child(2) > tr:nth-child(2) > th,
  .tableDailyReport > .v-data-table__wrapper > table > thead:nth-child(3) > tr > th{
    background: #1476bf !important;
    color: white !important;
    border-right: 0.1px solid white !important;
    border-left: 0.1px solid white !important;
  }

  .tableDailyReport > .v-data-table__wrapper > table > tbody > tr > td:nth-child(1){
    white-space: nowrap;
  }


  .container-result-generated{
    text-align: center;
  }

  .vChip-text{
    color:black !important;
  }

  .datePickerDiv >>> input {
    border: none;
    background: transparent;
  }

  .v-input--selection-controls__input{
    margin: 0px;
  }

  /* Custom vue default */
  .tableDailyReport > .v-data-table__wrapper {
    overflow-x: auto;
    overflow-y: auto;
    max-height: 480px;
  }

  .v-data-table__empty-wrapper > td{
    text-align: left !important;
  }

  /* Section Result Generated Chart Report */
  .form-chart{
    display: none;
  }

  .highcharts-credits{
    display: none !important;
  }

  /* Footer Report */
  .footer-report{
    background: white;
  }


  
  // .one-text-footer{
  //   margin: 0px 10px;
  // }



  /* Set image coloring for all sub themes: */
  /* background-image: linear-gradient(to bottom, #1476bf, #02B3C3); */

</style>